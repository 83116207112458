import React, { useState, useEffect } from "react";
import { auth } from "./firebase";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleLogin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
    } catch (error) {
      console.error("Error logging in: ", error);
    }
  };

  return (
    <div className="App">
      {user ? (
        <Dashboard user={user} />
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
